<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "APP",
  watch: {
    $route: {
      handler(newVal,oldVal) {
        const userInfo = localStorage.getItem('userInfo');
        if(userInfo){
          this.$axios.post(`/qz/home/view`, {}, {useLog: true})
        }
      }
    },
  }
};
</script>
<style lang="scss">
@font-face {
  font-family: 'changan uni type bold';
  src: url('@/fonts/长安引力体-BOLD_MIANFEIZITI.COM.TTF');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  /* 重命名字体名 */
  font-family: 'changan uni type';
  /* 引入字体 */
  src: url('@/fonts/CHANGANUNITYPE-LIGHT.TTF');
  // font-weight: normal;
  // font-style: normal;
}
@font-face {
  /* 重命名字体名 */
  font-family: 'YouSheBiaoTiHei';
  /* 引入字体 */
  src: url('@/fonts/YouSheBiaoTiHei-2.ttf');
  // font-weight: normal;
  // font-style: normal;
}
@font-face {
  /* 重命名字体名 */
  font-family: 'PingFangSC';
  /* 引入字体 */
  src: url('@/fonts/PingFangSC.ttf');
  // font-weight: normal;
  // font-style: normal;
}
@font-face {
    font-family: 'PingFangSC-Regular';  /* 字体名称 */
    src: url('@/fonts/PINGFANG REGULAR.TTF') format('truetype'); /* 字体文件的URL及其格式（如.ttf、.otf、.woff、.woff2等） */
    font-weight: normal;         /* 字体粗细 */
    font-style: normal;          /* 字体风格 */ 
}
@font-face {
    font-family: 'PingFangSC-Medium';  /* 字体名称 */
    src: url('@/fonts/PINGFANG MEDIUM.TTF') format('truetype'); /* 字体文件的URL及其格式（如.ttf、.otf、.woff、.woff2等） */
    font-weight: normal;         /* 字体粗细 */
    font-style: normal;          /* 字体风格 */ 
}
@font-face {
    font-family: 'PingFangSC-bold';  /* 字体名称 */
    src: url('@/fonts/PINGFANG BOLD.TTF') format('truetype'); /* 字体文件的URL及其格式（如.ttf、.otf、.woff、.woff2等） */
    font-weight: normal;         /* 字体粗细 */
    font-style: normal;          /* 字体风格 */ 
}
@font-face {
    font-family: 'PingFangSC-heavy';  /* 字体名称 */
    src: url('@/fonts/PINGFANG HEAVY.TTF') format('truetype'); /* 字体文件的URL及其格式（如.ttf、.otf、.woff、.woff2等） */
    font-weight: normal;         /* 字体粗细 */
    font-style: normal;          /* 字体风格 */ 
}
@font-face {
    font-family: 'ZhenyanGB';  /* 字体名称 */
    src: url('@/fonts/锐字真言体.ttf') format('truetype'); /* 字体文件的URL及其格式（如.ttf、.otf、.woff、.woff2等） */
    font-weight: normal;         /* 字体粗细 */
    font-style: normal;          /* 字体风格 */ 
}
html,
body,
div,
ul,
li,
input,
button,
img,
textarea,
p,
h1,
a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent; //禁用按压时有背景颜色
  box-sizing: border-box;
}
html,
body {
  position: relative;
}
ul,li{
  list-style: none;
}
a {
  text-decoration: none;
  color: #333;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #F6F9FE inset;
}
body {
  background: #f7fbfc;
  font-size: 14px !important;
}
img {
  font-size: 0;
  line-height: 0;
  object-fit: cover;
}
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.clean_float::after {
  content: "";
  height: 0;
  display: block;
  clear: both;
  zoom: 1;
}
.w_1200 {
  width: 1200px;
  margin: 0 auto;
}

.overflow_e {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.overflow_o {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// .overflow_1{
//   text-overflow: -o-ellipsis-lastline;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
// }
//清除浮动
.clearfix::after {
	content: '';
	display: table;
	clear: both;
}
/* 兼容IE低版本 */
.clearfix {
	*zoom: 1;
}
//单行省略号
.a-single-ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
//多行省略号
.vertical-ellipsis{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
