<template>
  <div class="m-home">
    <Header />
    <router-view />
  </div>
</template>
<script>
import Header from "@/components/m/header.vue";
export default {
  name: "MHome",
  data() {
    return {
      
    };
  },
  components: {
    Header
  },
}
</script>
<style lang="scss">
.m-home{
  width: 100%;
  min-height: 100vh;
  padding-top: 48px;
  background-color: #fff;
  div{
    font-family: PingFangSC-Medium;
    font-weight: 500;
  }
  img{
    display: block;
  }
  //可视区高
  .visual-area-height {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    padding-top: 48px;
    padding-bottom: 74px;
    overflow-y: auto;
    .page-bottom{
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
  }
}
</style>
