import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

//管理vuex
export default new Vuex.Store({
	state: {
		websiteState: '',
		//pc登录弹窗
		loginModal: {
			show: false
		},
		//m 登录弹窗
		mLoginModal: {
			show: false
		},
		//提示弹窗
		tipModal: {
			show: false,
			text: '',
			showBtns: false,
			fun: null,
			cancelFun: null,
			confirmFun: null,
			subBtnText: '',
			cancelBtnText: '',
			ico: ''
		},
		userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
	},
	mutations: {
		//设置网站信息
		SetWebsiteState(state, data){
			state.websiteState = data;
            localStorage.setItem("websiteState", JSON.stringify(data));
		},
		//获取网站信息
		GetWebsiteState(state){
			return state.websiteState ? state.websiteState : JSON.parse(localStorage.getItem('data'));
		},
		//pc显示tip
		ShowTipModal(state, data){
			state.tipModal.show = true;
			state.tipModal.text = data.text;
			state.tipModal.showBtns = data.showBtns ? data.showBtns : false;
			state.tipModal.fun = data.fun?data.fun:null;
			state.tipModal.confirmFun = data.confirmFun?data.confirmFun:null;
			state.tipModal.confirmBtnText = data.confirmBtnText?data.confirmBtnText:'确定';
			state.tipModal.cancelFun = data.cancelFun?data.cancelFun:null;
			state.tipModal.cancelBtnText = data.cancelBtnText?data.cancelBtnText:'取消';
			state.tipModal.ico = data.ico?data.ico:'fail';
		},
		//pc
		CloseTipModal(state, data){
			state.tipModal.show = false;
			state.tipModal.text = '';
		},
		ShowLoginModal(state){
			state.loginModal.show = true;
		},
		//pc
		CloseLoginModal(state){
			state.loginModal.show = false;
		},
		//m
		ShowMLoginModal(state){
			state.mLoginModal.show = true;
		},
		//m
		CloseMLoginModal(state){
			state.mLoginModal.show = false;
		},
		//获取用户信息
		GetUserInfo(state){
			if(localStorage.getItem('userInfo')){
				state.userInfo = JSON.parse(localStorage.getItem('userInfo'));
			}else{
				state.userInfo = JSON.parse(localStorage.getItem('user'));
			}
		},
		//清空用户信息
		ClearUserInfo(state){
			localStorage.removeItem('userInfo');
			state.userInfo = '';
		}
	}
})