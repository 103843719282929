<template>
    <div class="login_container">
        <!-- 登录 -->
        <div class="container_main login_cont_box" v-if="showIndex==1">
                q123123
        </div>
        <!-- 注册 -->
        <div class="container_main register_cont_box" v-if="showIndex==2"></div>
        <!-- 忘记密码 -->
        <div class="container_main forget_cont_box" v-if="showIndex==3"></div>
        <!-- 重置成功 -->
        <div class="container_main reset_cont_box" v-if="showIndex==4"></div>
    </div>
</template>
<script>
export default {
  name: "login_container",
  components: {
   
  },
  data() {
    return {  
        showIndex:1,
    };
  },
  mounted() {
    if(this.$route.query.type){
        this.showIndex = this.$route.query.type
    }
  },
  methods:{}
};
</script>
<style scoped lang="scss">
.login_container{
    
}
//   登录
.login_cont_box{
    background: url('@/assets/pc/login/bgm1.png') no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    position: relative;
    height: 1071px;
}
// 注册
.register_cont_box{
    
}
// 忘记密码
.forget_cont_box{
    
}
// 重置成功
.reset_cont_box{
    
}
  
</style>

