<template>
  <div class="header_box">
    <div class="head_cont_info">
      <p class="logo flex"><img src="@/assets/pc/logo.png" alt=""> <span>全国工匠学院</span></p>
      <div class="right_cont flex">
        <ul class="flex titleMenu">
          <li>
              <router-link :class="$route.path=='/'?'activeMenu':''" to="/">首页</router-link>
          </li>
          <li class="sec_menu">
            <router-link :class="$route.path=='/collegeInfo'?'activeMenu':''" to="/collegeInfo">学院介绍</router-link>
            <!-- <p  :class="$route.path=='/collegeInfo'?'activeMenu':''">学院介绍</p>
            <div class="list">
              <p class="name"><router-link :class="$route.path=='/collegeInfo?type=1'?'activeMenu':''" to="/collegeInfo?type=1">学院简介</router-link></p>
              <p class="name"><router-link :class="$route.path=='/collegeInfo?type=2'?'activeMenu':''" to="/collegeInfo?type=2">政策指导</router-link></p>
              <p class="name"><router-link :class="$route.path=='/collegeInfo?type=3'?'activeMenu':''" to="/collegeInfo?type=3">领导架构</router-link></p>
            </div> -->
          </li>
          <li @click="gzpt">工作平台</li>
          <!-- <li>
            <router-link :class="$route.path=='/site'?'activeMenu':''" to="/site">学院建设</router-link>
          </li> -->
          <li>数据中心</li>
          <li>
            <router-link :class="$route.path=='/newsList'?'activeMenu':''" to="/newsList">资讯中心</router-link>
          </li>
          <!-- <li>
            <router-link :class="$route.path=='/userCenter'?'activeMenu':''" to="/userCenter">个人中心</router-link>
          </li> -->
        </ul>
        <div class="user_cont flex" v-if="userInfo">
          <p class="avatar"><img :src="userInfo.image || require('@/assets/pc/login/per_img.png')"></p>
          <p class="name">{{ userInfo.name }}</p>
          <p class="down_icon"><img src="@/assets/pc/login/down_icon.png" alt=""></p>
          <div class="btn_list">
            <p @click="$router.push('/userCenter')">个人中心</p>
            <p @click="signOut()">退出登录</p>
          </div>
        </div>
        <div class="btn_info flex" v-else>
          <p class="btn login_btn" @click="$router.push('/login')">登录</p>
          <p class="line"></p>
          <p class="btn" @click="$router.push('/register')">注册</p>
        </div>
      </div>
    </div>
    <!-- 登录框 -->
    <!-- v-if="loginModal.show" -->
    <Login v-if="loginModal.show" :showIndex="showIndex" />
  </div>
</template>

<script>
import bus from '@/utils/bus.js'
import Login from "@/components/pc/login.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "headers",
  data() {
    return {
      showIndex: 1,
    };
  },
  props: {
    menu: Array,
    logo: {
      type: String,
      default: "",
    },
  },
  components: {
    Login,
  },
  computed: {
    ...mapState(["tipModal", "loginModal", "userInfo"]),
  },
  mounted() {
  },
  methods: {
    ...mapMutations([
      "ShowLoginModal",
      "CloseLoginModal",
      "GetUserInfo",
      "ClearUserInfo",
      "ShowTipModal",
    ]), //登录弹窗

    // 登录
    goLogin() {
      this.showIndex = 1;
      this.ShowLoginModal();
    },
    // 注册
    goRegister() {
      this.showIndex = 2;
      this.ShowLoginModal();
    },
    // 退出登录
    signOut(){
      localStorage.clear()
      location.reload();
    },
    //工作平台
    gzpt(){
      bus.$emit("toGzpt");
    }
  },
};
</script>

<style scoped lang="scss">
// *{
//   margin: 0;
//   padding: 0;
// }
.header_box {
  height: 80px;
  background: #fff;
  .head_cont_info{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p.logo{
      // width: 199px;
      display: flex;
      img{
        width: 58px;
        height: auto;
      }
      span{
        font-size: 28px;
        color: #3C3C3C;
        margin-left: 15px;
        font-weight: 500;
      }
    }
    ul.titleMenu{
      flex: 1;
      li{
        margin-right: 32px;
        position: relative;
        height: 80px;
        line-height: 80px;
        font-size: 16px;
        color: #333333;
        cursor: pointer;
        a{
          font-size: 16px;
          color: #333333;
        }
      }
      .sec_menu{
        position: relative;
        .list{
          display:none;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 80px;
          z-index: 5;
          background: rgba(41, 115, 240, .8);
          width: 140px;
          text-align: center;
          color: #fff;
          p{
            line-height: 60px;
            a{
              color: #fff;
            }
          }
          &::before{
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -20px;
            width: 0;
            height: 0;
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent rgba(41, 115, 240, .8) transparent;
          }
        }
        &:hover{
          .list{
            display: block;
          }
        }
        
      }
      .activeMenu::after {
        content: "";
        width: 21px;
        height: 2px;
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0px;
        background-color: #0080ff;
        border-radius: 30px;
      }
      .activeMenu {
        color: #0080ff;
        font-size: 16px;
      }
    }
    .user_cont{
      margin-left: 64px;
      position: relative;
      p.avatar{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
        }
      }
      p.name{
        font-size: 16px;
        color: #666666;
        margin: 0 10px;
      }
      p.down_icon{
        width: 16px;
        height: 16px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .btn_list{
        padding: 5px 0;
        display: none;
        position: absolute;
        left: 60%;
        transform: translateX(-50%);
        top: 25px;
        width: 120px;
        background: #FFFFFF;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        z-index: 9;
        text-align: center;
        p{
          line-height: 30px;
          padding: 5px 0;
          cursor: pointer;
          font-size: 15px;
          color: #666;
          &:hover{
            color: #0080ff;
          }
        }
      }
      &:hover .btn_list{
        display: block;
      }
    }
    .btn_info{
      margin-left: 64px;
      p.btn{
        font-size: 16px;
        color: #333333;
        cursor: pointer;
      }
      p.login_btn{
        color: #2173EC;
      }
      p.line{
        width: 1px;
        height: 20px;
        background: #707070;
        margin: 0 25px;
      }
    }
  }
 
  

    .login_box {
      p.search_icon {
        width: 18px;
        margin-right: 10px;
        img {
          width: 18px;
          display: block;
        }
      }
      .login_state {
        .text_login {
          p {
            font-size: 14px;
            color: #333333;
            margin-right: 2px;
            cursor: pointer;
          }
        }
        .per_info {
          // border-left: 1px solid #e8e8e8;
          margin-left: 21px;
          padding-left: 21px;
          img {
            width: 27px;
            height: 27px;
            border-radius: 100%;
          }
        }
      }
    }
  
}
.per_info {
  position: relative;
}
.per_info:hover {
  .sign_out {
    display: block;
  }
}
// 退出登录
.sign_out {
  display: none;
  position: absolute;
  left: -20px;
  top: 25px;
  z-index: 9;
  width: 120px;
  min-height: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px 5px 5px 5px;
  box-sizing: border-box;
  // padding: 20px 20px 12px 20px;

  div.btn {
    margin-top: 14px;
    justify-content: space-between;
    p {
      cursor: pointer;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      flex: 1;
      text-align: center;
    }
  }
}
</style>
