let urlHttp = window.location.href
let urlHead = 'https'
if (urlHttp.includes('https')) {
    urlHead = 'https'
}
// export const ApiUrl = 'http://dlgjxyapi.kjcxchina.com';
export const ApiUrl = 'https://qzgjxyadmin.kjcxchina.com';
//判断客户端
export const IsPC = () => {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];

    let flag = true;

    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break
        }
    }

    return flag
};
// 验证手机号
export const IsRegular = (data) => {
    return /^1[3456789]\d{9}$/.test(data)
}
// 验证身份证号
export const VerificationCard = (data) => {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(data)
}
//判断是否为微信浏览器
export const IsWeiXin = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') != -1;

    if (isWeixin) {
        return true;
    } else {
        return false;
    }
}

//手机号/邮箱脱敏
export const Desensitization = (data) => {
    if (data.includes('@')) {
        let email = data.split('@');
        if (email[0].length > 4) {
            return email[0].substring(0, email[0].length - 3) + '*****' + email[1];
        } else {
            return '*****' + email[1];
        }
    } else {
        return data.slice(0, 3) + "****" + data.slice(data.length - 4);
    }
}

const QuillToolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['link', 'image', 'video'],
    ['clean']                                         // remove formatting button
];

export const QuillEditorOption = {
    placeholder: '请填写创新成果',
    modules: {
        imageResize: {
            displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
        },
        toolbar: {
            container: QuillToolbarOptions,  // 工具栏
        }
    }
}

