<template>
  <div class="pc-home">
    <header-menu v-if="headerState" :logo="logoUrl" />
    <router-view />
    <foot-menu  :style="topNum?'margin-top:'+'0px':'margin-top:120px'" v-if="footerState" :deepLogoUrl='deepLogoUrl' />
  </div>
</template>
<script>
import headerMenu from "@/components/pc/header.vue";
import footMenu from "@/components/pc/footer.vue";
import { mapMutations } from "vuex";

export default {
  name: "pc-home",
  components: {
    headerMenu,
    footMenu,
  },
  data() {
    return {
      headerState: true,
      footerState: true,
      logoUrl: '',
      deepLogoUrl:'',
      topNum:'',
    };
  },
  watch: {
    $route: {
      handler(newVal,oldVal) {
        this.CloseLoginModal()
        // console.log(newVal)
        const { headerIsShow, footerIsShow,topState } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        this.topNum =  topState ? true : false;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if(oldVal&&oldVal.path=='/login' && localStorage.getItem('userInfo')){
          location.reload()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getWebsiteInfo(); //获取网站配置信息
    this.urlState = window.location.host.includes("form_data")
      ? "false"
      : "true";
  },
  methods: {
    ...mapMutations(["CloseLoginModal", "ShowTipModal"]), //登录弹窗

    getWebsiteInfo() {
      
    },
  },
};
</script>

<style lang="scss">
.pc-home{
  *{
    font-family: PingFangSC;
  }
  .container_box{
    padding-bottom: 150px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .cursor {
    cursor: pointer;
  }
  .none {
    display: none;
  }
  input::-webkit-input-placeholder {
    font-size: 14px;
  }
  input::-moz-input-placeholder {
    font-size: 14px;
  }
  input::-ms-input-placeholder {
    font-size: 14px;
  }
  .overflow_e {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    word-break: break-all; // 注意这个文字多行很重要
    -webkit-box-orient: vertical;
  }
  .overflow_o {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }
  .abs_50{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .radius_5{
    border-radius: 5px;
  }
}
</style>
